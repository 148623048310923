<template>
  <div>
    <el-card
      :body-style="{ padding: '0px' }"
      class="course_card_data"
      :class="$i18n.locale === 'ar' ? 'text-right' : ''"
    >
      <!-- :lazy-src="image(course)" -->
      <div class="img_wrapper">
        <img
          v-if="course.img"
          :src="course.img"
          max-height="100%"
          class="image"
          draggable="false"
        />
        <img
          v-else
          src="@/assets/images/banner.jpg"
          max-height="100%"
          class="image"
          draggable="false"
        />
      </div>
      <!-- <img :src="image(course)" max-height="100%" class="image" /> -->
      <div class="content_wrapper  mx-2">
        <div class="w-100">
          <span>
            <router-link
              v-if="hasRole('teacher') || hasRole('admin')"
              :to="`/course/${id}`"
            >
              {{ title }}
            </router-link>
            <a v-else>
              {{ title }}
            </a>
          </span>
        </div>
        <div class="w-100">
          {{ justSpecificWords(description) }}
        </div>
        <!-- only show for student  -->
        <div class="bottom w-100">
          <i
            @click="getCourseDetails(course)"
            class="fa fa-eye show_icons shadow-sm"
          ></i>
          <el-button
            class="mx-2 mb-4"
            @click="$emit('addCourse', id)"
            style="font-size: 15px"
            dark
            medium
            type="success"
            v-if="hasRole('student')"
          >
            {{ $t("add_course") }}
          </el-button>
          <el-button
            class="mx-2 mb-2"
            @click="$emit('remove', id)"
            fab
            dark
            small
            type="danger"
            v-if="hasPermission('admin_courses')"
          >
            {{ $t("delete") }}
          </el-button>
        </div>
      </div>
    </el-card>

    <!-- Start Dialog -->
    <el-dialog
      :visible.sync="showDetails"
      :title="courseData.title"
      transition="dialog-bottom-transition"
      width="70%"
      top="4vh"
      class="text-right"
      :show-close="false"
    >
      <div class="course_container">
        <div class="images">
          <img
            v-if="courseData.img"
            draggable="false"
            class="course_img shadow-sm"
            :src="courseData.img"
            alt=""
          />
          <img
            v-else
            src="@/assets/images/banner.jpg"
            class="course_img shadow-sm"
            draggable="false"
          />
        </div>
        <p class="course_text">
          {{ courseData.description }}
        </p>
        <div class="close_footer">
          <button @click="showDetails = false" class="close_close">
            اغلاق
          </button>
        </div>
      </div>
    </el-dialog>
    <!-- End Dialog -->
  </div>
</template>

<script>
export default {
  name: "Ccard",
  props: {
    id: null,
    title: null,
    description: null,
    course: null,
    isLink: true,
  },
  data() {
    return {
      load: false,
      dialog: false,
      image_src:
        "https://media.istockphoto.com/photos/back-view-of-elementary-students-raising-their-arms-on-a-class-picture-id1000887536?k=6&m=1000887536&s=612x612&w=0&h=_766UYb-oBcmrJQfIn5RU6SWLhTVMLszO9JT5USUDQ0=",
      remove_dialog: false,
      // id: null,
      showDetails: false,
      courseData: {},
    };
  },
  methods: {
    // add(id, status) {
    //   this.load = true;
    //   this.$store
    //     .dispatch("courses/add", { id: id, status: status })
    //     .then((_) => {
    //       this.load = false;
    //       this.$emit("refresh");
    //     });
    //   this.dialog = false;
    // },
    image(course) {
      if (course.cover) {
        return `https://justtolearn.com/api/courses/cover/${course.cover}`;
      }
      return this.image_src;
    },
    // remove(id) {
    //   this.$emit("remove", id);
    //   this.remove_dialog = false;
    // },
    // openDialog(id) {
    //   this.dialog = true;
    //   this.id = id;
    // },
    // openRemoveDialog(id) {
    //   this.remove_dialog = true;
    //   this.id = id;
    // },
    getCourseDetails(item) {
      this.showDetails = true;
      this.courseData = item;
      console.log(item);
    },
  },
};
</script>

<style lang="scss" scoped>
/* .el-card__body {
      margin: 10px !important;
  } */

.course_card_data {
  min-height: 500px;
  .img_wrapper {
    .image {
      height: 250px;
      padding: 5px;
      object-fit: contain;
    }
  }
  .content_wrapper {
    padding: 10px;
    height: 250px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.course_container {
  .images {
    .course_img {
      max-width: 400px;
      max-height: 400px;
      margin: 0 auto 20px;
      display: block;
      padding: 10px;
      border-radius: 10px;
    }
  }
  .course_text {
    font-size: 18px;
    line-height: 1.7;
    word-break: break-word;
    padding: 10px;
    text-align: center;
  }
  .close_footer {
    margin-top: 20px;
    .close_close {
      padding: 8px 26px;
      border-radius: 5px;
      border: none;
      background-color: #f56c6c;
      color: #fff;
    }
  }
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .show_icons {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    color: #777;
    &:hover {
      background-color: #23ccef;
      color: #fff;
    }
  }
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
  height: 250px;
  margin: auto;
}

span {
  font-size: 1.2rem;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

/* delete form style  */

::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}
</style>
