<template>
  <div class="col" v-loading="$store.getters['courses/load']">
    <el-row>
      <!-- <div>
        <button @click="testDialogData = true">Test Dialog Data</button>
      </div> -->
      <div class="mb-4 d-flex">
        <el-button tile v-if="hasPermission('admin_courses')" type="success" dark @click="dialog = true"
          style="margin-right: 10px">
          <el-icon left>mdi-plus</el-icon>
          {{ $t("add_course") }}
        </el-button>
      </div>
      <el-form ref="form" :model="form" style="max-width: 50rem">
        <el-form-item>
          <el-input v-model="form.search" :placeholder="$t('search')" style="font-size: 1rem"></el-input>
        </el-form-item>
      </el-form>
    </el-row>

    <!-- <el-row>
      <el-col :span="24" v-if="hasRole('teacher')">
        <el-button type="success" class="float-right" @click="dialog2 = true">
          <el-icon left>mdi-plus</el-icon>
          إضافة مجموعة
        </el-button>
      </el-col>
      <el-col v-for="course in coursesData" :key="course.id" :xs="24" :lg="8">
        <CCard
          style="margin: 10px"
          :id="course.id"
          @refresh="Refresh"
          @addCourse="openAddCourse"
          :course="course"
          :isLink="false"
          :title="course.title"
          :description="course.description"
          @remove="remove"
        ></CCard>
      </el-col>
    </el-row> -->

    <div class="row" dir="ltr">
      <!-- <div class="col-sm-12" v-if="hasRole('admin')">
        <el-button type="success" class="float-right" @click="dialog2 = true">
          <el-icon left>mdi-plus</el-icon>
          {{ $t("add_group") }}
        </el-button>
      </div> -->

      <div class="col-xl-4" v-for="course in coursesData" :key="course.id">
        <CCard style="margin: 10px; height: 100%" :id="course.id" @refresh="Refresh" @addCourse="openAddCourse"
          :course="course" :isLink="false" :title="getCourseTitle(course.title)"
          :description="getCourseDescription(course.description)" @remove="remove"></CCard>
      </div>
    </div>

    <!-- add group dialog  -->
    <el-dialog :visible.sync="dialog2" :title="$t('add_group')" transition="dialog-bottom-transition" width="70%"
      top="4vh" class="text-right">
      <el-form :model="group" :rules="addGroupRules" ref="groupForm" class="demo-ruleForm" label-position="top"
        v-loading="$store.getters['groups/load']">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('Class_name')" prop="name">
              <el-input :label="$t('Class_name')" v-model="group.name" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :lg="12">
            <el-form-item :label="$t('Global.days')" prop="days">
              <el-select :items="days" v-model="group.days" :label="$t('Global.days')" multiple class="w-100">
                <el-option v-for="(value, key) in days" :key="key" :label="value" :value="value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :lg="12">
            <el-form-item :label="$t('courses')" prop="course_id">
              <el-select :items="$store.getters['courses/courses']" v-model="group.course_id" item-text="title"
                item-value="id" :label="$t('courses')" class="w-100">
                <el-option v-for="(value, key) in $store.getters['courses/courses']" :key="key" :label="value.title"
                  :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :lg="12">
            <el-form-item :label="$t('Global.end_at')" prop="end_at">
              <el-date-picker type="date" :placeholder="$t('Global.end_at')" v-model="group.end_at"
                value-format="yyyy-MM-dd" class="w-100" :picker-options="datePickerOptions"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24" :lg="12">
            <el-form-item :label="$t('Global.start_from')" prop="start_from">
              <el-date-picker type="date" :placeholder="$t('Global.start_from')" v-model="group.start_from"
                value-format="yyyy-MM-dd" class="w-100" :picker-options="datePickerOptions"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('Global.age_class')" prop="age_group">
              <el-select :items="age_group" v-model="group.age_group" item-text="title" item-value="id" class="w-100"
                :label="$t('Global.age_class')">
                <el-option v-for="(value, key) in age_group" :key="key" :label="value.title" :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Global.zoom_link')" prop="zoom_link">
              <el-input type="textarea" :label="$t('Global.zoom_link')" class="w-100"
                v-model="group.zoom_link"></el-input>
            </el-form-item>
          </el-col>
          <!-- Start New Fields [Grade, School, Country, City] -->
          <el-col :span="24">
            <el-form-item :label="$t('Global.grade')" prop="grade">
              <el-input :label="$t('Global.grade')" v-model="group.grade" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Global.school')" prop="school">
              <el-input :label="$t('Global.school')" v-model="group.school" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Global.country')" prop="country">
              <el-input :label="$t('Global.country')" v-model="group.country" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Global.city')" prop="city">
              <el-input :label="$t('Global.city')" v-model="group.city" class="w-100"></el-input>
            </el-form-item>
          </el-col>
          <!-- End New -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto" dir="ltr">
        <el-button type="info" class="text-center ml-lg-2 mx-auto d-block w-100 my-1" text @click="dialog2 = false">
          {{ $t("cancel") }}
        </el-button>
        <el-button class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary mx-2" text @click="saveGroup">
          {{ $t("save_group") }}
          {{
            $store.state.uploadProgress && $store.state.uploadProgress != 100
              ? `${$store.state.uploadProgress} %`
              : ""
          }}
        </el-button>
      </span>
    </el-dialog>

    <!-- join course group dialog -->
    <el-dialog :visible.sync="add_course_dialog" :title="$t('add_course')" transition="dialog-bottom-transition"
      width="70%" top="4vh">
      <el-form :model="addedCourse" :rules="addedCourseRules" ref="addedCourseForm" label-position="top"
        v-loading="$store.getters['groups/load']">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('groups')" prop="id">
              <el-select v-model="addedCourse.id" :label="$t('groups')" class="w-100">
                <el-option v-for="(value, key) in $store.getters['groups/courseGroups']" :key="key" :label="value.name"
                  :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('children')" prop="student_id">
              <el-select v-model="addedCourse.student_id" :placeholder="$t('children')" style="width: 100%" multiple>
                <el-option v-for="(item, index) in $store.getters['user/child']" :key="index" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Global.promo_code')" prop="promo_code">
              <el-input :label="$t('Global.promo_code')" v-model="addedCourse.promo_code" class="w-100"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto" dir="ltr">
        <el-button type="info" class="text-center ml-lg-2 mx-auto d-block w-100 my-1" text
          @click="add_course_dialog = false">
          {{ $t("cancel") }}
        </el-button>
        <el-button class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary mx-2" text @click="joinCourseGroup">
          {{ $t("join") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- //todo Start add new course dialog  -->
    <el-dialog :visible.sync="dialog" :title="$t('add_course')" transition="dialog-bottom-transition" width="70%"
      top="4vh">
      <el-form :model="course" ref="courseRef" :rules="courseRules" class="demo-ruleForm" label-position="top"
        v-loading="$store.getters['courses/load']">
        <!-- Language Toggle Buttons -->
        <el-row>
          <el-col :span="24" class="text-center">
            <el-radio-group v-model="selectedLanguage">
              <el-radio-button label="en">{{
                $t("CoursesGroup.english")
                }}</el-radio-button>
              <el-radio-button label="ar">{{
                $t("CoursesGroup.arabic")
                }}</el-radio-button>
              <el-radio-button label="gr">{{
                $t("CoursesGroup.germany")
                }}</el-radio-button>
            </el-radio-group>
          </el-col>
        </el-row>

        <!-- Title Input -->
        <el-row>
          <el-col :span="24" :sm="24" v-if="selectedLanguage === 'en'">
            <el-form-item :label="$t('Global.course_name') +
              ' (' +
              $t('CoursesGroup.english') +
              ')'
              " prop="title.en">
              <el-input v-model="course.title.en"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="24" v-if="selectedLanguage === 'ar'">
            <el-form-item :label="$t('Global.course_name') +
              ' (' +
              $t('CoursesGroup.arabic') +
              ')'
              " prop="title.ar">
              <el-input v-model="course.title.ar"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="24" v-if="selectedLanguage === 'gr'">
            <el-form-item :label="$t('Global.course_name') +
              ' (' +
              $t('CoursesGroup.germany') +
              ')'
              " prop="title.gr">
              <el-input v-model="course.title.gr"></el-input>
            </el-form-item>
          </el-col>
          <!-- Cover Image Input -->
          <el-col :span="24" :sm="24">
            <el-form-item :label="$t('image')" class="file_input align-center" cover>
              <input type="file" @change="upload_add_course_file" ref="new_course_file"
                accept="image/apng, image/jpeg, image/png, image/webp" style="display: none" class="file-input" />
              <el-button @click="triggerFileInput">
                <i class="fa fa-file-image-o fa-3x file-icon"></i>
              </el-button>
              <el-button @click="uploadCourseFile">{{
                $t("preview_image")
                }}</el-button>
              <div class="el-input__inner input_file_placeholder">
                {{ add_course_file_name }}
              </div>
              <div class="image-preview" v-if="previewImage">
                <img :src="previewImage" alt="Course Cover Preview" />
              </div>
            </el-form-item>
          </el-col>

          <!-- Description Input -->
          <el-col :span="24" v-if="selectedLanguage === 'en'">
            <el-form-item :label="$t('description') + ' (' + $t('CoursesGroup.english') + ')'
              " prop="description.en">
              <el-input type="textarea" class="purple-input our_course_text_area" v-model="course.description.en" />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="selectedLanguage === 'ar'">
            <el-form-item :label="$t('description') + ' (' + $t('CoursesGroup.arabic') + ')'
              " prop="description.ar">
              <el-input type="textarea" class="purple-input our_course_text_area" v-model="course.description.ar" />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="selectedLanguage === 'gr'">
            <el-form-item :label="$t('description') + ' (' + $t('CoursesGroup.germany') + ')'
              " prop="description.gr">
              <el-input type="textarea" class="purple-input our_course_text_area" v-model="course.description.gr" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto" dir="ltr">
        <el-button type="info" class="text-center ml-lg-2 mx-auto d-block w-100 my-1" text @click="dialog = false">
          {{ $t("cancel") }}
        </el-button>
        <el-button class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary mx-2" text @click="save">
          {{ $t("add_course") }}
          {{
            $store.state.uploadProgress && $store.state.uploadProgress != 100
              ? `${$store.state.uploadProgress} %`
              : ""
          }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { log } from "three/examples/jsm/nodes/Nodes.js";
import CCard from "../../components/Cards/CCard";
import Swal from "sweetalert2";

export default {
  name: "courses",
  components: { CCard },
  data() {
    return {
      rules: {
        title: [(v) => !!v || this.$t("course_name_required")],
        description: [(v) => !!v || this.$t("course_description_required")],
        groupRules: [(v) => !!v || this.$t("class_number_required")],
        nameRules: [(v) => !!v || this.$t("class_name_required")],
        dayesRule: [(v) => !!v || this.$t("course_info_required")],
        coursesRule: [(v) => !!v || this.$t("course_required")],
        imgRules: [(v) => !!v || this.$t("image_required")],
      },
      // image_src: 'https://media.istockphoto.com/photos/back-view-of-elementary-students-raising-their-arms-on-a-class-picture-id1000887536?k=6&m=1000887536&s=612x612&w=0&h=_766UYb-oBcmrJQfIn5RU6SWLhTVMLszO9JT5USUDQ0=',
      days: [
        this.$t("sunday"),
        this.$t("monday"),
        this.$t("tuesday"),
        this.$t("wednesday"),
        this.$t("thursday"),
        this.$t("friday"),
        this.$t("saturday"),
        // "Sunday",
        // "Monday",
        // "Tuesday",
        // "Wednesday",
        // "Thursday",
        // "Friday",
      ],
      age_group: [
        { id: 1, title: this.$t("less_than_6") },
        { id: 2, title: this.$t("from_6_to_8") },
        { id: 3, title: this.$t("from_9_to_12") },
        { id: 4, title: this.$t("more_than_13") },
        { id: 5, title: this.$t("all_ages") },
      ],
      dialog: false,
      dialog2: false,
      dialogDelete: false,
      currentDelete: null,
      // search form
      form: {
        search: "",
      },
      coursesData: {},
      selectedLanguage: "en",
      supportedLanguages: ["en", "ar", "gr"], // Add your supported languages here
      course: {
        title: {
          en: "",
          ar: "",
          gr: "",
        },
        cover: null,
        description: {
          en: "",
          ar: "",
          gr: "",
        },
      },
      previewImage: null,
      group: {
        id: "",
      },
      datePickerOptions: {
        disabledDate(date) {
          return date <= new Date();
        },
      },

      // add course data
      add_course_dialog: false,
      addedCourse: {
        status: 1,
      },
      add_course_file_name: "",
      addedCourseRules: {
        promo_code: [
          {
            required: true,
            message: this.$t("promo_code_required"),
            trigger: "blur",
          },
        ],

        student_id: [
          {
            required: true,
            message: this.$t("child_required"),
            trigger: "change",
          },
        ],

        id: [
          {
            required: true,
            message: this.$t("group_required"),
            trigger: "change",
          },
        ],
      },
      addGroupRules: {
        name: [
          {
            required: true,
            message: this.$t("course_name_required"),
            trigger: "blur",
          },
        ],
        days: [
          {
            required: true,
            message: this.$t("days_required"),
            trigger: "blur",
          },
        ],
        course_id: [
          {
            required: true,
            message: this.$t("course_required"),
            trigger: "blur",
          },
        ],
        start_from: [
          {
            required: true,
            message: this.$t("start_from_required"),
            trigger: "blur",
          },
        ],
        end_at: [
          {
            required: true,
            message: this.$t("end_at_required"),
            trigger: "blur",
          },
        ],
        age_group: [
          {
            required: true,
            message: this.$t("age_class_required"),
            trigger: "blur",
          },
        ],
        grade: [
          {
            required: true,
            message: this.$t("grade_required"),
            trigger: "blur",
          },
        ],
        school: [
          {
            required: true,
            message: this.$t("school_required"),
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: this.$t("country_required"),
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: this.$t("city_required"),
            trigger: "blur",
          },
        ],

        // name: [(v) => !!v || this.$t("course_name_required")],
        // description: [(v) => !!v || this.$t("course_description_required")],
        // groupRules: [(v) => !!v || this.$t("group_number_required")],
        // nameRules: [(v) => !!v || this.$t("group_name_required")],
        // dayesRule: [(v) => !!v || this.$t("course_info_required")],
        // coursesRule: [(v) => !!v || this.$t("course_required")],
        // imgRules: [(v) => !!v || this.$t("image_required")],
      },
      // For Admin When Add New Course
      courseRules: {
        "title.en": [
          {
            required: true,
            message: this.$t("title_required"),
            trigger: "blur",
          },
        ],
        "description.en": [
          {
            required: true,
            message: this.$t("description_required"),
            trigger: "blur",
          },
        ],
        "title.ar": [
          {
            required: true,
            message: this.$t("title_required"),
            trigger: "blur",
          },
        ],
        "description.ar": [
          {
            required: true,
            message: this.$t("description_required"),
            trigger: "blur",
          },
        ],
        "title.gr": [
          {
            required: true,
            message: this.$t("title_required"),
            trigger: "blur",
          },
        ],
        "description.gr": [
          {
            required: true,
            message: this.$t("description_required"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.Refresh(null);
    // get children of user - only needed once loaded
    this.$store.dispatch("user/user", this.auth().id);
  },
  watch: {
    // perform local search
    "form.search": function () {
      if (this.form.search.length > 0) {
        // if user inputs search text, return match only
        this.coursesData = this.$store.getters["courses/courses"].filter(
          (course) =>
            course.title.toLowerCase().includes(this.form.search.toLowerCase())
        );
      } else {
        // return all data since bno search
        this.coursesData = this.$store.getters["courses/courses"];
      }
    },
  },
  methods: {
    triggerFileInput() {
      this.$refs.new_course_file.click();
    },
    upload_add_course_file(event) {
      const file = event.target.files[0];
      console.log("Selected file:", file);
      if (file) {
        this.add_course_file_name = file.name;
        const reader = new FileReader();
        reader.onload = (e) => {
          console.log("FileReader onload event:", e);
          this.previewImage = e.target.result;
          console.log("Updated previewImage:", this.previewImage);
        };
        reader.onerror = (error) => {
          console.error("FileReader error:", error);
        };
        reader.readAsDataURL(file);
        console.log("FileReader readAsDataURL called");
      }
    },
    uploadCourseFile() {
      console.log("Upload button clicked");
      const fileInput = this.$refs.new_course_file;
      if (fileInput && fileInput.files[0]) {
        const file = fileInput.files[0];
        console.log("File ready for upload:", file);
        this.add_course_file_name = file.name;

        const reader = new FileReader();
        reader.onload = (e) => {
          console.log("FileReader onload event:", e);
          this.previewImage = e.target.result;
          console.log("Updated previewImage:", this.previewImage);
        };
        reader.onerror = (error) => {
          console.error("FileReader error:", error);
        };
        reader.readAsDataURL(file); // Pass the file object here
        console.log("FileReader readAsDataURL called");
      } else {
        console.log("No file selected for upload");
      }
    },

    upload_add_course_file(event) {
      console.log("ggggggggggggg");
      const file = event.target.files[0];
      console.log("Selected file:", file);
      if (file) {
        this.add_course_file_name = file.name;
        const reader = new FileReader();
        reader.onload = (e) => {
          console.log("FileReader onload event:", e);
          this.previewImage = e.target.result;
          console.log("Updated previewImage:", this.previewImage);
        };
        reader.onerror = (error) => {
          console.error("FileReader error:", error);
        };
        reader.readAsDataURL(file);
        console.log("FileReader readAsDataURL called");
      }
    },
    save() {
      console.log("valid", this.$refs.courseRef);
      this.$refs.courseRef.validate((valid, errors) => {
        console.log("valid", valid);
        if (valid) {
          let formData = new FormData();
          for (let key in this.course) {
            if (key === "title" || key === "description") {
              formData.append(key, JSON.stringify(this.course[key]));
            } else {
              formData.append(key, this.course[key]);
            }
          }
          formData.append("cover", this.$refs["new_course_file"].files[0]);

          this.$store
            .dispatch("courses/save", { id: "", query: formData })
            .then(() => {
              this.Refresh();
              this.resetForm();
              this.dialog = false;
            });
        } else {
          console.log("Validation failed:", errors);
        }
      });
    },
    resetForm() {
      this.course = {
        title: { en: "", ar: "", fr: "" },
        cover: null,
        description: { en: "", ar: "", fr: "" },
      };
      this.add_course_file_name = "";
    },
    saveGroup() {
      this.$refs["groupForm"].validate((valid) => {
        if (valid) {
          // console.log("hellllllllllllllllo");
          this.$store.dispatch("groups/addGroup", this.group).then((_) => {
            this.Refresh();
            this.dialog2 = false;
            this.group = {};
          });
        } else {
          console.log("nottttttttt");
        }
      });
      // if (this.$refs.groupForm.validate()) {
      //   this.$store.dispatch("groups/addGroup", this.group).then((_) => {
      //     this.Refresh();
      //     this.dialog2 = false;
      //     this.group = {};
      //   });
      // }
    },
    Refresh(query) {
      this.$store
        .dispatch("courses/list", { query: query })
        .then((response) => {
          this.coursesData = this.$store.getters["courses/courses"];
        });
    },
    remove(id) {
      // this.dialogDelete = true;
      // this.currentDelete = id;
      // this.$store.dispatch('courses/delete', id).then(_ => {
      //   this.Refresh(null);
      // })

      Swal.fire({
        title: this.$t("confirm_course_delete"),
        text: this.$t("ask_course_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("courses/delete", id).then((_) => {
            this.Refresh(null);
            Swal.fire({
              title: this.$t("deleted"),
              text: this.$t("course_deleted"),
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },
    getCourseTitle(title) {
      return this.getLocalizedText(title);
    },
    getCourseDescription(description) {
      return this.getLocalizedText(description);
    },
    getLessonTitle(title) {
      return this.getLocalizedText(title);
    },
    getLocalizedText(jsonString) {
      const locale = this.$i18n.locale;
      if (jsonString) {
        try {
          const parsedData = JSON.parse(jsonString);
          console.log("Parsed Data:", parsedData);
          return parsedData[locale] || parsedData["en"]; // Fallback to English if the locale is not found
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return jsonString; // Return the original string if it's not valid JSON
        }
      } else {
        return "";
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      // console.log('vasdadlkjasflkjasdlkjaslkdjalskd ', this.currentDelete)
      this.$store.dispatch("courses/delete", this.currentDelete).then((_) => {
        this.Refresh(null);
      });
      this.dialogDelete = false;
    },
    handleAvatarSuccess(res, file) {
      // console.log("filefilefilefilefilefile ======> ", file.raw);
      this.course.cover = file.name;
    },
    openAddCourse(id) {
      this.add_course_dialog = true;
      this.$store.dispatch("groups/courseGroups", id);
    },
    joinCourseGroup() {
      this.$refs["addedCourseForm"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("groups/addGroupStudent", this.addedCourse)
            .then(() => {
              this.add_course_dialog = false;
              this.$router.push("/auth/courses");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    upload_add_course_file() {
      this.add_course_file_name = this.$refs["new_course_file"].files[0].name;
      // console.log("test");
    },
  },
};
</script>

<style scoped>
.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
}

::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .text-right .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}

::v-deep .el-radio {
  margin-left: 30px;
  margin-right: 0;
}

::v-deep .el-radio__input {
  margin-left: 10px;
}

::v-deep .el-textarea__inner {
  height: 250px !important;
  resize: none;
}

/* form select inputs  */
.el-select-dropdown__item {
  padding: 5px 40px;
  text-align: right;
}

/* style for file input  */
.file_input {
  position: relative;
}

.input_file_placeholder {
  position: absolute;
  border: none;
  border-bottom: 1px solid #dcdfe6;
  top: 21px;
  width: calc(100% - 2em);
}

.input_file_placeholder::before {
  content: "";
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: 10;
  left: 0;
  cursor: pointer;
}

.selectchildren {
  padding: 7px;
}

.file_input {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file_input input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.file_input_placeholder {
  width: 100%;
  height: 40px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
}

.image-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.image-preview img {
  max-width: 100%;
  max-height: 200px;
  border-radius: 4px;
}
</style>
